import {Box, chakra, Flex, Link as ChakraLink, Text} from '@chakra-ui/react'
import {Helmet} from "react-helmet";
import React from 'react';
import '../css/main.css'
import 'react-medium-image-zoom/dist/styles.css'
import {graphql, Link} from 'gatsby';
import {MyMasonry} from '../components/MyMasonry';

const LinkText: React.FC = ({children}) => {

    return <Text as={"span"} fontFamily="Monoton" fontSize={{base: "lg", lg: "3xl"}} letterSpacing="0.1em"
                 fontWeight="" color="gray.600" _hover={{color: "gray.900"}}>{children}</Text>
}

const Index = ({data}: { data: any }) => {

    return (
        <Flex direction={{base: "column", lg: "row"}} w="full" overflow="hidden" h="100vh">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Jelena Šijak</title>
                <meta name="description" content="My photography portfolio"/>
                <link rel="canonical" href="http://jelenasijak.com"/>

                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Mrs+Saint+Delafield&family=Ubuntu:wght@300;400&family=Luckiest+Guy&family=Monoton&display=swap"
                    rel="stylesheet"/>
            </Helmet>
            <Flex backgroundColor="#f5e3e6"
                  backgroundImage="linear-gradient(315deg, #f5e3e6 0%, #d9e4f5 74%)"
                  direction="column" w="full" maxW={{base: "full", lg: "300px"}} alignItems="center"
                  justifyContent="space-between" pt={{base: 12, lg: 24}} pb={{base: 12, lg: 4}}>
                <Flex alignItems="flex-start" flexWrap="wrap" direction={{base: "row", lg: "column"}} gridGap={4}>
                    <ChakraLink as={Link} to={"/about"}>
                            <LinkText>About</LinkText>
                    </ChakraLink>
                    <ChakraLink href={"https://www.instagram.com/jelena.sijak.photography/"} target={"_blank"}>
                        <LinkText>Instagram</LinkText>
                    </ChakraLink>
                    <ChakraLink as={Link} to={"/"}>
                            <LinkText>Projects</LinkText>
                    </ChakraLink>
                    <ChakraLink href={"mailto:jekasijak@gmail.com"} target={"_blank"}>
                        <LinkText>Contact</LinkText>
                    </ChakraLink>
                </Flex>
                <Box textAlign="center" display={{base: "none", lg: "block"}}>
                    <Text fontFamily="Ubuntu" fontWeight={300} color="gray.600">Photographed with ❤️ in Serbia.</Text>
                    <Text>
                        <chakra.span fontFamily="Mrs Saint Delafield" fontSize="4xl" color="gray.700">Jelena Šijak
                        </chakra.span>
                    </Text>
                </Box>
            </Flex>
            <Box w="full" overflow="scroll" backgroundColor="#fff">
                <MyMasonry images={data.allFile.nodes}/>
            </Box>
        </Flex>
    )
}

export default Index

export const query = graphql`
    {
        allFile(filter
    :
        {
            absolutePath: {
                regex: "/images/"
            }
        }
    )
        {
            nodes
            {
                name
                childImageSharp
                {
                    gatsbyImageData(width
                :
                    920, placeholder
                :
                    TRACED_SVG, layout
                :
                    CONSTRAINED
                )
                }
            }
        }
    }
`
